<template>
    <div class="text-center">
        <h4 class="mt-4 text-center">שתפו את התהילים היומי</h4>
        <v-row class="mx-auto mt-2"
            justify="center">
            
            <ShareNetwork
                v-for="platform in platforms"
                :network="platform.network"
                :key="platform.network"
                :url="share.url"
                :title="share.title"
                :description="share.description"
                :quote="share.quote"
                :hashtags="share.hashtags"
                :twitterUser="share.twitterUser">

                <span>
                    <v-btn class="ma-1" x-small fab dark color="primary">
                        <v-icon small dark color="white">{{ platform.icon }}</v-icon>
                    </v-btn>
                </span>
                
                
            </ShareNetwork>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {

        }
    },
    methods: {
        ...mapActions('storeShare', ['setShare']),
        setShareData() {
            let share = {
                url: 'https://amisraelive.com/tehilimDay',
                title: 'קריאת תהילים יומי',
                description: 'עם ישראל חי'
            }
            this.setShare(share)
      },
    },
    computed: {
        ...mapGetters('storeShare', ['platforms', 'share'])
    },
    created() {
        this.setShareData()
    }
}

</script>


<style scoped>

 *, ::before, ::after{
  text-decoration: none !important;
}

</style>